// Switch element
.form-switch {
  @apply relative select-none;
  width: 68px;

  label {
    @apply block overflow-hidden cursor-pointer rounded;
    height: 38px;

    > span:first-child {
      @apply absolute block rounded shadow;
      width: 30px;
      height: 30px;
      top: 4px;
      left: 4px;
      right: 50%;
      transition: all 0.15s ease-out;
    }
  }

  input[type="checkbox"] {
    &:checked {
      + label {
        @apply bg-blue-600;

        > span:first-child {
          left: 34px;
        }
      }
    }
  }
}
